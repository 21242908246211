// Generated by Framer (f712822)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import PlusIconSmall from "./ovCH22Xmz";
const PlusIconSmallFonts = getFonts(PlusIconSmall);

const serializationHash = "framer-yeU3d"

const variantClassNames = {mgJNjDVQG: "framer-v-pd8xvy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({feature, height, id, width, ...props}) => { return {...props, M_iM6fWPB: feature ?? props.M_iM6fWPB ?? "Text"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;feature?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, M_iM6fWPB, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "mgJNjDVQG", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-pd8xvy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mgJNjDVQG"} ref={refBinding} style={{...style}}><ComponentViewportProvider height={18} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 22) - 0) - 18) / 2)))}><SmartComponentScopedContainer className={"framer-j2wvgd-container"} layoutDependency={layoutDependency} layoutId={"n9pNXSDsu-container"} nodeId={"n9pNXSDsu"} rendersWithMotion scopeId={"kO8bO08Ia"}><PlusIconSmall CgCxwDz_B CquvwTJCF={"rgb(51, 51, 51)"} height={"100%"} id={"n9pNXSDsu"} L_MXIE6eA={"rgba(255, 255, 255, 0.1)"} layoutId={"n9pNXSDsu"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "140%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(51, 51, 51))"}}>Text</motion.p></React.Fragment>} className={"framer-voezez"} data-framer-name={"Feature Description"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"rmhLyHqlk"} style={{"--extracted-r6o4lv": "rgb(51, 51, 51)", "--framer-paragraph-spacing": "0px"}} text={M_iM6fWPB} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yeU3d.framer-1y634qw, .framer-yeU3d .framer-1y634qw { display: block; }", ".framer-yeU3d.framer-pd8xvy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-yeU3d .framer-j2wvgd-container { flex: none; height: auto; position: relative; width: auto; }", ".framer-yeU3d .framer-voezez { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-yeU3d.framer-pd8xvy { gap: 0px; } .framer-yeU3d.framer-pd8xvy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-yeU3d.framer-pd8xvy > :first-child { margin-left: 0px; } .framer-yeU3d.framer-pd8xvy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 58
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"M_iM6fWPB":"feature"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerkO8bO08Ia: React.ComponentType<Props> = withCSS(Component, css, "framer-yeU3d") as typeof Component;
export default FramerkO8bO08Ia;

FramerkO8bO08Ia.displayName = "Feature Item";

FramerkO8bO08Ia.defaultProps = {height: 22, width: 58};

addPropertyControls(FramerkO8bO08Ia, {M_iM6fWPB: {defaultValue: "Text", displayTextArea: true, title: "Feature", type: ControlType.String}} as any)

addFonts(FramerkO8bO08Ia, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}, ...PlusIconSmallFonts], {supportsExplicitInterCodegen: true})